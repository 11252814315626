import React, { useState, useEffect } from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import { Box } from "theme-ui"
import axios from "axios"
import cloudinaryOptimize from "../../../helpers/cloudinaryOptimize"
import NonActiveEvents from "./NonActiveEvents"
import ActiveEvent from "./ActiveEvent"
import useStories from "../../../hooks/useStories"
import MultipleLinksSelector from "../../Links/MultipleLinksSelector"
let jsonpAdapter = require("axios-jsonp")

const EventsShowcase = () => {
  const [regularEventData, setRegularEventData] = useState(null)
  const [recurringEventData, setRecurringEventData] = useState(null)
  const [allEvents, setAllEvents] = useState([])
  const [activeEvent, setActiveEvent] = useState({})

  const regularEventsURL = `https://data.prod.gonation.com/profile/events?profile_id=bzn-yO3xgUsKQCS7GWg0Q2ewbQ`
  const recurringEventsURL = `https://data.prod.gonation.com/profile/recurringevents?profile_id=bzn-yO3xgUsKQCS7GWg0Q2ewbQ`

  useEffect(() => {
    axios({
      url: regularEventsURL,
      adapter: jsonpAdapter,
    }).then(res => {
      setRegularEventData(res)
    })

    axios({
      url: recurringEventsURL,
      adapter: jsonpAdapter,
    }).then(res => {
      setRecurringEventData(res)
    })
  }, [])

  useEffect(() => {
    if (regularEventData && recurringEventData) {
      const regEvents = regularEventData.data.events
      const recEvents = recurringEventData.data.events

      setAllEvents(normalizeEvents(regEvents.concat(recEvents)))
      setActiveEvent(normalizeEvents(regEvents.concat(recEvents))[0])
    }
  }, [regularEventData, recurringEventData])

  const normalizeEvents = events => {
    return events.map(event => {
      if (event.eventDays) {
        return {
          isRecurring: true,
          createdAt: event.createdAt,
          days: event.eventDays,
          description: event.description,
          tags: event.eventTags,
          name: event.name,
          starts: event.starts,
          image: event.imageurl,
          ctas: event.ctas,
          profile_id: event.profile_id,
          _id: event._id,
        }
      } else
        return {
          isRecurring: false,
          createdAt: event.createdAt,
          description: event.description,
          tags: event.eventTags,
          name: event.name,
          starts: event.starts,
          image: event.imageurl,
          ctas: event.ctas,
          profile_id: event.profile_id,
          _id: event._id,
        }
    })
  }

  const { getStoryByTag } = useStories()

  const story = getStoryByTag("homepage-events")

  const renderModule = () => {
    return (
      <>
        <Box sx={{ alignItems: "center", justifyContent: "center" }}>
          <Title className="title">{story?.title}</Title>
          <Subtitle>{story?.subtitle}</Subtitle>
          <Text dangerouslySetInnerHTML={{ __html: story.body }} />

          <MultipleLinksSelector
            links={story?.ctaLinks}
            sx={{
              ".primaryButton": {
                variant: "buttons.quaternary",
              },
            }}
          />
        </Box>
        <DesktopEventsRow>
          <IsHiddenMobile>
            <NonActiveEvents
              allEvents={allEvents}
              setActiveEvent={setActiveEvent}
              activeEvent={activeEvent}
            />
          </IsHiddenMobile>

          <ActiveEvent
            activeEvent={activeEvent}
            allEvents={allEvents}
            setActiveEvent={setActiveEvent}
          />
        </DesktopEventsRow>
      </>
    )
  }
  return allEvents.length ? (
    <Container>{allEvents.length && renderModule()}</Container>
  ) : (
    ""
  )
}

const DesktopEventsRow = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 3rem;
  @media (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
    flex-direction: row;
  }
`
//
const Container = styled.div`
  max-width: 1344px;
  margin: 0 auto;
  padding: 1rem 0;
  padding: 2rem;

  @media (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
    padding: 3rem 0;
  }
`

const Title = styled.h3`
  font-size: 2rem !important;
  text-align: center;
  color: white;

  @media (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
    font-size: 3rem !important;
  }
`

const Subtitle = styled.p`
  color: ${props => (props.theme.light ? props.theme.light : "white")};
  text-align: center;
  font-size: 1.25rem;
  margin-bottom: 2rem;
  font-weight: 400;

  @media (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
    font-size: 2rem;
  }
`

const Text = styled.div`
  font-size: 1.5rem !important;
  text-align: center;
  color: white;
  margin-bottom: 2rem;
`

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 2rem;
  a {
    background: ${props =>
      props.theme.secondary ? props.theme.secondary : "black"};
    border: 2px solid
      ${props => (props.theme.secondary ? props.theme.secondary : "black")};
    color: white;
    text-transform: uppercase;
    display: inline-flex;
    align-items: center;
    font-weight: 500;
    padding: 0.75rem 2rem;
    border-radius: 4px;
    font-size: 1rem;
    &:hover {
      background: transparent;
      color: $secondary;
      transition: all 0.3s;
      path {
        fill: $secondary;
      }
    }
  }
`

const IsHiddenMobile = styled.div`
  width: 15%;
  margin-bottom: 3rem;
  @media (max-width: 768px) {
    display: none;
  }
`

export default EventsShowcase
