import React from "react"
import cloudinaryOptimize from "../helpers/cloudinaryOptimize"
import styled from "styled-components"
import { Button, Box } from "theme-ui"

const FixedBackgroundImage = styled.div`
  ${props => `
    background: ${props.overlay ? props.overlay : ""} url(${cloudinaryOptimize(
    props.image,
    2000
  )})
  `};

  height: 500px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  padding: 2rem 1.5rem;
  text-align: center;

  @media (min-width: ${({ theme }) => theme.breakpoints.desktop}) {
    background-attachment: fixed;
    padding: 2rem 5rem;
  }
`

const Text = styled.a`
  display: inline-block;
  text-align: "center";
  font-weight: 500;
  ${({ flipStyles }) => (flipStyles ? "font-size: 1.5rem;" : "2rem;")}
  text-transform: uppercase;
  color: white;

  @media (min-width: 900px) {
    font-size: 3rem;
    ${({ flipStyles }) => (flipStyles ? "font-size: 1.5rem;" : "")}
  }

  &:hover {
    color: #fff;
    text-decoration: underline;
    transition: all 0.3s;
  }
`

const Coordinates = styled.p`
  font-size: 1.5rem;
  text-align: center;
  color: #fff;
  font-weight: 300;

  ${({ flipStyles }) =>
    flipStyles ? "font-size: 3rem; font-weight: 500; margin-top: 2rem;" : ""}
`

const ParallaxImage = ({
  image,
  heading,
  subHeading,
  link,
  isGatsbyLink,
  overlay,
  flipStyles,
  cta,
}) => {
  const renderCTAButton = () => {
    return (
      <a href={cta.url} target="_blank" rel="noopener noreferrer">
        <Button sx={{ cursor: "pointer" }}>{cta.label}</Button>
      </a>
    )
  }
  return (
    <FixedBackgroundImage image={image} overlay={overlay}>
      <Text
        href={link}
        target="_blank"
        rel="noopener noreferrer"
        flipStyles={flipStyles}
      >
        {heading}
      </Text>
      <div>
        <Coordinates flipStyles={flipStyles}>{subHeading}</Coordinates>
      </div>
      <Box sx={{ marginTop: 3 }}>{cta ? renderCTAButton() : ""}</Box>
    </FixedBackgroundImage>
  )
}

export default ParallaxImage
