import cloudinaryOptimize from "../helpers/cloudinaryOptimize"
export default [
  {
    name: "Fall",
    img: cloudinaryOptimize(
      "https://res.cloudinary.com/gonation/image/upload/v1630529151/sites/dockside-brewing/fall.jpg"
    ),
  },
  {
    name: "Winter",
    img: cloudinaryOptimize(
      "https://res.cloudinary.com/gonation/image/upload/v1630529151/sites/dockside-brewing/winter.jpg"
    ),
  },
  {
    name: "Spring",
    img: cloudinaryOptimize(
      "https://res.cloudinary.com/gonation/image/upload/v1630529151/sites/dockside-brewing/spring.jpg"
    ),
  },
  {
    name: "Summer",
    img: cloudinaryOptimize(
      "https://res.cloudinary.com/gonation/image/upload/v1630529150/sites/dockside-brewing/summer.jpg"
    ),
  },
]
