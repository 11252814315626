import React, { useState } from "react"

export default function DocksideSubscribe() {
  const [email, setEmail] = useState("")
  return (
    <div
      style={{
        backgroundColor: "rgb(201,79,65)",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        padding: "2rem .5rem",
      }}
    >
      <h2
        style={{
          color: "white",
          fontSize: "2rem",
          textAlign: "center",
          padding: "1rem 0",
        }}
      >
        Become a Dockside Insider
      </h2>
      <div style={{ backgroundColor: "rgb(201,79,65)" }} id="mc_embed_signup">
        <form
          action="https://docksidebrewery.us4.list-manage.com/subscribe/post?u=d8ee0d625446f3c6eb4a0348b&amp;id=0c24ddddbf"
          method="post"
          id="mc-embedded-subscribe-form"
          name="mc-embedded-subscribe-form"
          className="validate"
          target="_blank"
          rel="noopener"
          novalidate
        >
          <div id="mc_embed_signup_scroll" className="signup_scroll">
            <input
              type="email"
              onChange={e => setEmail(e.target.value)}
              value={email}
              name="EMAIL"
              className="email"
              id="mce-EMAIL"
              placeholder="email address"
              required
              style={{
                background: "transparent",
                border: "none",
                borderBottom: "1px solid #fff",
                color: "#fff",
                outline: "none",
                ":placeholder": {
                  color: "#fff",
                },
              }}
            />
            <div
              style={{ position: "absolute", left: "-5000px" }}
              aria-hidden="true"
            >
              <input
                type="text"
                name="b_d8ee0d625446f3c6eb4a0348b_0c24ddddbf"
                tabindex="-1"
                value=""
              />
            </div>
            <div className="clear">
              <input
                type="submit"
                value="Subscribe"
                name="subscribe"
                id="mc-embedded-subscribe"
                className="button"
              />
            </div>
          </div>
        </form>
      </div>
    </div>
  )
}
