import React, { useState, useEffect } from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import Img from "react-cloudinary-lazy-image"
import Lightbox from "react-image-lightbox"
import axios from "axios"
import showcaseBG from "../../assets/dsb.png"

let jsonpAdapter = require("axios-jsonp")

const MenuShowcase = () => {
  const [lightBox, setLightbox] = useState({
    isOpen: false,
    mainSrc: "",
  })
  const [pizzaData, setPizzaData] = useState(null)
  const [activeItem, setActiveItem] = useState(null)
  const pizzaURL =
    "https://data.prod.gonation.com/pl/get?profile_id=bzn-yO3xgUsKQCS7GWg0Q2ewbQ&callback=foo&powerlistId=powered-list-6"

  useEffect(() => {
    axios({
      url: pizzaURL,
      adapter: jsonpAdapter,
    }).then(res => {
      const pizzas = res.data[0].inventory.filter(
        ({ item }, idx) => idx < 7 && item && item.photo_id !== null
      )
      setActiveItem(pizzas[0].item)
      setPizzaData(pizzas)
    })
  }, [])

  const removeImageCopy = img => {
    if (img.includes("copy-")) {
      return img.replace("copy-", "")
    }
    if (img.includes("-copy")) {
      return img.replace("-copy", "")
    }
    return img
  }

  const renderShowcase = () => {
    return (
      <MenuColumn>
        {lightBox.isOpen && (
          <Lightbox
            mainSrc={lightBox.mainSrc}
            onCloseRequest={() => setLightbox({ isOpen: false })}
          ></Lightbox>
        )}
        <Title className="title">New Haven classic brick oven pizza</Title>
        <ActiveImageContainer
          onClick={() =>
            setLightbox({
              isOpen: true,
              mainSrc: removeImageCopy(activeItem.imageUrl),
            })
          }
        >
          <Img
            cloudName={"gonation"}
            imageName={removeImageCopy(activeItem.imagePrefix)}
            fluid={{
              maxWidth: 700,
              // height: 700,
            }}
            alt={activeItem.name}
            style={{
              borderRadius: "4px",
              height: "100%",
              maxHeight: "400px",
              width: "100%",
            }}
          ></Img>
        </ActiveImageContainer>

        <ImageThumbs>
          {pizzaData
            .filter(el => el.item.name !== activeItem.name)
            .map(el => {
              return (
                <div onClick={() => setActiveItem(el.item)}>
                  <Img
                    cloudName="gonation"
                    imageName={removeImageCopy(el.item.imagePrefix)}
                    fluid={{ maxWidth: 800 }}
                    alt={el.item.name}
                  ></Img>
                </div>
              )
            })}
        </ImageThumbs>
      </MenuColumn>
    )
  }

  return (
    <Container>
      {pizzaData && activeItem && renderShowcase()}

      <TextColumn>
        <OverlayImg>
          <img src={showcaseBG} alt="Dockside" />
        </OverlayImg>
        <TryTitle>
          <span>{activeItem && activeItem.name}</span>
        </TryTitle>
        <TryDescription className="try-desc">
          {activeItem && activeItem.desc}{" "}
        </TryDescription>
        <Link to="/menu" style={{ zIndex: 3 }}>
          <Button>View Full Menu</Button>
        </Link>
      </TextColumn>
    </Container>
  )
}

export default MenuShowcase

const Container = styled.div`
  /* max-width: 1344px; */
  margin: 0 auto;
  padding: 1rem;

  @media (min-width: 1024px) {
    display: flex;
    align-items: stretch;
  }
`
const ActiveImageContainer = styled.div`
  /* margin-bottom: 1rem; */

  img {
    @media (min-width: 1024px) {
      height: 100%;
    }
  }
`

const ImageThumbs = styled.div`
  display: flex;
  > div {
    cursor: pointer;
    flex: 1;
    padding: 0.25rem;

    @media (min-width: 1024px) {
      padding: 0;
      margin-top: 1rem;
      &:not(:last-of-type) {
        margin-right: 1rem;
      }
    }

    &:first-child {
      border-left: 0px solid #fff;
    }
    &:last-child {
      border-right: 0px solid #fff;
    }
    img {
      border-radius: 4px;
      width: 100%;
      object-fit: cover;
      height: 55px !important;
      display: flex;
      @media (min-width: 1024px) {
        height: 140px !important;
      }
    }
  }
`

const MenuColumn = styled.div`
  z-index: 9;
  width: 100%;
  @media (min-width: 1024px) {
    width: 50%;
  }
`

const Title = styled.h3`
  margin: 1rem 0;
  padding: 0;
  font-size: 2rem;
  word-break: break-word;
  line-height: 1.125;
  font-weight: 900;
  text-transform: uppercase;
  /* color: ${props =>
    props.theme.secondary ? props.theme.secondary : "black"}; */
  color: #111;
  @media (min-width: 1024px) {
    font-size: 4rem;
  }
`

const TextColumn = styled.div`
  width: 100%;
  z-index: 4;
  position: relative;
  @media (min-width: 1024px) {
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 4rem;
    > div {
      margin: 4rem 0 !important;
    }
  }
`
const TextTitle = styled.h4`
  .title {
    font-size: 1.75rem;
    color: #111;
    @media (min-width: 1024px) {
      font-size: 2.75rem;
      text-align: center;
    }
    @media (min-width: 1024px) {
      font-size: 3rem;
    }
    span {
      color: ${props => (props.theme.primary ? props.theme.primary : "black")};
      display: inline-block;
      margin-top: 0.5rem;
    }
  }
`

const TryTitle = styled.p`
  margin-top: 1rem;
  font-size: 3rem;
  text-transform: uppercase;
  line-height: 2.75rem;
  z-index: 9;
  margin-bottom: 1rem;
  @media (min-width: 1024px) {
    /* text-align: center; */
  }
  span {
    color: ${props => (props.theme.primary ? props.theme.primary : "black")};
  }
`

const TryDescription = styled.p`
  margin-bottom: 3rem;
  z-index: 9;
  font-size: 1.25rem;
  @media (min-width: 1024px) {
    margin-bottom: 4rem;
    /* text-align: center; */
  }
`

const Button = styled.button`
  background-color: ${props =>
    props.theme.secondary ? props.theme.secondary : "black"};
  border: 2px solid
    ${props => (props.theme.secondary ? props.theme.secondary : "black")};
  color: white;
  cursor: pointer;
  padding: 0.75rem 2rem;
  text-align: center;
  white-space: nowrap;
  border-radius: 4px;
  box-shadow: none;
  font-size: 14px;
  text-transform: uppercase;
  font-weight: bold;
  z-index: 8;
  &:hover {
    border-color: ${({ theme }) => theme.primary};
    background: ${({ theme }) => theme.primary};
    transition: all 0.3s;
  }
`

const OverlayImg = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  /* padding-right: 9rem; */
  margin: 2rem 0;
  padding: 1rem;
  padding-top: 0;
  img {
    max-height: 800px;
  }
  @media (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
    padding: 4rem;
    z-index: 0;
    left: 180px;
  }
`
