import React, { useState } from "react"
import styled from "styled-components"
import moment from "moment"
import Lightbox from "react-image-lightbox"
import cloudinaryOptimize from "../../../helpers/cloudinaryOptimize"
import { Link } from "gatsby"

import NonActiveEvents from "./NonActiveEvents"

export default function ActiveEvent({
  activeEvent,
  allEvents,
  setActiveEvent,
}) {
  const [lightBox, setLightbox] = useState({
    isOpen: false,
    mainSrc: "",
  })
  const getEventTime = event => {
    if (event.isRecurring) {
      const eventDays = Object.keys(event.days)
      return <Date>Every {eventDays.map(day => day)}</Date>
    }
    return <Date>{moment(event.starts).format("MMMM Do YYYY, h:mm a")}</Date>
  }

  const addHTTP = url => {
    var prefix = "https://"
    if (url.substr(0, prefix.length) !== prefix) {
      return prefix + url
    }
    return url
  }

  const renderCTAs = ctas => {
    const buttonTitles = Object.keys(ctas).filter(
      btn => btn !== "cta1" && btn !== "cta2"
    )
    return buttonTitles.map(button => (
      <CTAButton
        target="_blank"
        rel="noopener noreferrer"
        href={addHTTP(ctas[button])}
      >
        {button}
      </CTAButton>
    ))
  }

  return (
    <Container>
      {lightBox.isOpen && (
        <Lightbox
          mainSrc={lightBox.mainSrc}
          onCloseRequest={() => setLightbox({ isOpen: false })}
        ></Lightbox>
      )}
      <Columns className="columns is-marginless is-paddingless">
        <Column className="column is-paddingless">
          <EventImageContainer>
            <EventImage
              onClick={() =>
                setLightbox({
                  isOpen: true,
                  mainSrc: activeEvent.image,
                })
              }
              src={cloudinaryOptimize(activeEvent.image, 600)}
              alt={activeEvent.name}
            />
          </EventImageContainer>
          <NonActiveEvents
            activeEvent={activeEvent}
            allEvents={allEvents}
            setActiveEvent={setActiveEvent}
            mobile
          />
        </Column>
        <Column className="column" style={{ postion: "relative" }}>
          <Title>{activeEvent.name}</Title>
          <DateContainer>{getEventTime(activeEvent)}</DateContainer>

          <Description>
            {activeEvent.description.length > 100
              ? `${activeEvent.description.substring(0, 100)}...`
              : activeEvent.description}
          </Description>
          <TagsTitle>Event Tags:</TagsTitle>
          <EventTags>
            {activeEvent &&
              activeEvent.tags &&
              activeEvent.tags.map(tag => <Tag>{tag}</Tag>)}
          </EventTags>
          <Flex>
            <Button>
              <a
                href={`https://gonation.com/place/dockside-brewery/events/${activeEvent._id}/info`}
                target="_blank"
                rel="noopener noreferrer"
              >
                View This Event
              </a>
              {renderCTAs(activeEvent.ctas)}
            </Button>
            <Button>
              <Link to="/events">View All Events</Link>
            </Button>
          </Flex>
        </Column>
      </Columns>
    </Container>
  )
}

const Container = styled.div``

const Columns = styled.div``

const Column = styled.div`
  display: flex;
  padding: 1rem 0;
  flex-direction: column;
  justify-content: flex-start;

  @media (min-width: 1024px) {
    padding: 3rem;
    padding-top: 0 !important;
  }
`

const EventImageContainer = styled.div`
  height: 100%;
`

const EventImage = styled.img`
  width: 100%;
`

const Title = styled.h4`
  font-size: 1.5rem;
  margin-bottom: 1.5rem;
  font-weight: bold;
  text-transform: uppercase;
  color: white;

  @media (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
    font-size: 2rem;
  }
`
const DateContainer = styled.div``

const Date = styled.div`
  color: ${props => (props.theme.light ? props.theme.light : "white")};
  font-size: 1.25rem;
  margin-bottom: 1.5rem;
  text-transform: capitalize;

  @media (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
    font-size: 1.75rem;
  }
`

const Description = styled.p`
  font-size: 1rem;
  font-weight: 500;
  line-height: 2rem;
  margin-bottom: 1rem;
`

const TagsTitle = styled.h4`
  font-size: 1rem;
  font-weight: 500;
  color: ${props => (props.theme.light ? props.theme.light : "white")};
`

const EventTags = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 0rem;
`
const Tag = styled.div`
  color: #fff;
  border: 1px solid #fff;
  border-radius: 30px;
  padding: 0.25rem 0.75rem;
  margin: 0.5rem 0rem;
  margin-right: 0.5rem;
  font-size: 12px;
`

const Button = styled.div`
  background: ${props =>
    props.theme.secondary ? props.theme.secondary : "black"};
  color: white;
  text-transform: uppercase;
  display: inline-flex;
  align-items: center;
  font-weight: 500;
  padding: 0.75rem 2rem;
  border-radius: 4px;
  padding: 0.75rem 1rem;
  font-size: 0.75rem;
  align-self: center;
  margin-top: 2rem;

  cursor: pointer;

  a {
    color: white;
  }
  &:hover {
    background: ${({ theme }) => theme.primary};
    color: #fff;
    transition: all 0.3s;
    path {
      fill: $secondary;
    }
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
    padding: 0.75rem 2rem;
    align-self: flex-start;
    margin-right: 2rem;
    font-size: 1rem;
  }
`

const Flex = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-evenly;

  @media (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
    flex-direction: row;
    justify-content: flex-start;
  }
`

const CTAButton = styled.a`
  color: white;
  display: inline;
  &:hover {
    text-decoration: underline;
    color: #eee;
  }
`
