import React, { useState, useEffect } from "react"
import styled from "styled-components"
import axios from "axios"
import moment from "moment"
let jsonpAdapter = require("axios-jsonp")

const Shout = () => {
  const [shoutData, setShoutData] = useState(null)
  const shoutURL =
    "https://data.prod.gonation.com/profile/shoutsnew/bzn-yO3xgUsKQCS7GWg0Q2ewbQ"

  useEffect(() => {
    axios({
      url: shoutURL,
      adapter: jsonpAdapter,
    }).then(res => {
      setShoutData(res.data)
    })
  }, [])

  const getShoutImage = () => {
    return `https://res.cloudinary.com/gonation/q_auto,f_auto,w_330/${shoutData.shout.image.image.cloudinaryId}.webp`
  }

  const addHTTP = url => {
    var prefix = "https://"
    if (url.substr(0, prefix.length) !== prefix) {
      return prefix + url
    }
    return url
  }

  const renderCTAs = () => {
    const buttonNames = Object.keys(shoutData.shout.ctas)
    return buttonNames
      .filter(btn => btn !== "cta1" && btn !== "cta2")
      .map(button => {
        return (
          <a
            target="_blank"
            rel="noopener noreferrer"
            href={addHTTP(shoutData.shout.ctas[button])}
            style={{ textDecoration: "underline" }}
          >
            {button}
          </a>
        )
      })
  }

  return (
    <Container>
      {shoutData ? (
        <>
          <Title>Recent shout</Title>
          <Message>{shoutData.shout.text}</Message>
          <p>{shoutData.shout.ctas && renderCTAs()}</p>
          <Date>
            Dockside shouts {moment(shoutData.shout.createdAt).fromNow()}
          </Date>
          {shoutData.shout.image.isDefault ? (
            ""
          ) : (
            <ImageContainer>
              <Image src={getShoutImage()} alt="Dockside Brewery Shout image" />
            </ImageContainer>
          )}
        </>
      ) : (
        ""
      )}
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
`

const Title = styled.h3`
  font-size: 1.5rem;
  color: ${props => (props.theme.primary ? props.theme.primary : "black")};
  font-weight: bold;
  text-transform: uppercase;
  text-align: left;
`

const BusinessName = styled.h2`
  font-size: 1.75rem;
  font-weight: bold;
  color: ${props => (props.theme.primary ? props.theme.primary : "black")};
  text-transform: uppercase;
`
const Message = styled.p`
  font-size: 1rem;
  line-height: 1.75rem;
  font-weight: 500;
  margin-bottom: 0.75rem;
`
const Date = styled.p`
  font-weight: 400;
  font-size: 0.75rem;
  margin: 0;
  font-weight: 500;
  margin-bottom: 0.75rem;
`

const ImageContainer = styled.div`
  margin-top: 2rem;
  width: 100%;
`

const Image = styled.img`
  width: 100%;
`

export default Shout
