import React from "react"
import styled from "styled-components"
import cloudinaryOptimize from "../../../helpers/cloudinaryOptimize"
export default function NonActiveEvents({
  allEvents,
  activeEvent,
  setActiveEvent,
  mobile
}) {
  return (
    <Container>
      <EventsImagesContainer className="" mobile={mobile}>
        {allEvents
          .filter((event, idx) => event._id !== activeEvent._id && idx < 4)
          .map(event => (
            <EventImageContainer
              className=""
              onClick={() => setActiveEvent(event)}
            >
              <EventImage
                src={cloudinaryOptimize(event.image, 400)}
                alt={event.name}
              />
            </EventImageContainer>
          ))}
      </EventsImagesContainer>
    </Container>
  )
}

const Container = styled.div`
  margin-bottom: 1.25rem;

  @media (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
    width: 100%;
    margin-bottom: 3rem;
  }
`

const EventsImagesContainer = styled.div`
  display: flex;
  justify-content: space-between;

  @media (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
    justify-content: flex-start;
    padding-right: 1rem;
    justify-content: center;
    flex-direction: column;
    ${props => (props.mobile ? "display: none;" : "")}
  }

  > div {
    width: 30%;
    @media (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
      width: auto;
    }
  }
`

const EventImageContainer = styled.div``

const EventImage = styled.img`
  width: 100%;
  max-height: none;
  height: 78px;
  @media (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
    height: 125px;
  }
`
