import React, { useState } from "react"
import styled from "styled-components"
import Img from "react-cloudinary-lazy-image"
import { useStaticQuery, graphql } from "gatsby"
import Lightbox from "react-image-lightbox"
import { Box, Flex, Image } from "theme-ui"
import useStories from "../../hooks/useStories"
import TextContent from "../TextContent"
import cloudinaryString from "../../helpers/cloudinaryString"
import MultipleLinksSelector from "../Links/MultipleLinksSelector"

export default function Curbside() {
  const [lightBox, setLightbox] = useState({
    isOpen: false,
    mainSrc: "",
  })

  const { getStoryByTag } = useStories()
  const story = getStoryByTag("homepage-online-order")
  const {
    body,
    createdAt,
    description,
    id,
    name,
    subtitle,
    tags,
    title,
    media,
    ctaLinks,
  } = story || {}

  if (!story) return null

  return (
    <Box sx={{ backgroundColor: "primary", color: "white" }}>
      {lightBox.isOpen && (
        <Lightbox
          mainSrc={lightBox.mainSrc}
          onCloseRequest={() => setLightbox({ isOpen: false })}
        ></Lightbox>
      )}
      <Flex sx={{ flexDirection: ["column", "", "row"] }}>
        <Box
          sx={{
            p: ["2rem 1rem", "3rem 2rem", "4rem 3rem"],
            flex: 1,
            width: ["100%", "", "50%"],
          }}
        >
          <TextContent title={title} subtitle={subtitle} body={body} />
          <MultipleLinksSelector
            links={ctaLinks}
            sx={{
              ".primaryButton": {
                variant: "buttons.tertiary",
              },
            }}
          />
        </Box>
        <Box
          sx={{ display: "flex", flex: 1, width: ["100%", "", "50%"] }}
          onClick={() =>
            setLightbox({
              isOpen: true,
              mainSrc: cloudinaryString(media?.[0].cloudinaryId, 2000) ?? null,
            })
          }
        >
          <Image
            src={cloudinaryString(media?.[0].cloudinaryId, 2000)}
            alt={title}
          />
        </Box>
      </Flex>
    </Box>
  )
}
