import React from "react"
import Slider from "react-slick"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import cloudinaryString from "../helpers/cloudinaryString"

const Modal = ({ closeModal, story }) => {
  const Arrow = ({ className, style, onClick, direction }) => (
    <div
      className={className}
      style={{
        ...style,
        display: "block",
        backgroundColor: "rgba(0, 0, 0, 0.5)",
        borderRadius: "50%",
        width: "40px",
        height: "40px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        zIndex: 2,
        ...(direction === "left" ? { left: "10px" } : { right: "10px" }),
      }}
      onClick={onClick}
    >
      <span
        style={{
          fontSize: "20px",
          color: "white",
          fontWeight: "bold",
          userSelect: "none",
        }}
      >
        {/* {direction === "left" ? "<" : ">"} */}
      </span>
    </div>
  )

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <Arrow direction="right" />,
    prevArrow: <Arrow direction="left" />,
  }

  return (
    <div
      style={{
        position: "fixed",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        backgroundColor: "rgba(0, 0, 0, 0.7)",
        zIndex: 1000,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div
        style={{
          backgroundColor: "#014e71",
          borderRadius: "8px",
          maxWidth: "90%",
          maxHeight: "90%",
          overflow: "hidden",
          position: "relative",
        }}
      >
        <button
          onClick={closeModal}
          style={{
            position: "absolute",
            top: "10px",
            right: "10px",
            backgroundColor: "red",
            color: "white",
            border: "none",
            borderRadius: "50%",
            width: "32px",
            height: "32px",
            cursor: "pointer",
            fontSize: "16px",
            fontWeight: "bold",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            zIndex: 99999,
          }}
        >
          X
        </button>
        {story.media.length > 1 ? (
          <Slider {...settings}>
            {story.media.map((media, index) => (
              <div key={index} style={{ textAlign: "center" }}>
                <img
                  src={cloudinaryString(media.cloudinaryId)}
                  alt={story.title}
                  style={{
                    maxWidth: "100%",
                    maxHeight: "60vh",
                    margin: "0 auto",
                    objectFit: "contain",
                  }}
                />
              </div>
            ))}
          </Slider>
        ) : (
          <div style={{ textAlign: "center" }}>
            <img
              src={cloudinaryString(story.media[0].cloudinaryId)}
              alt={story.title}
              style={{
                maxWidth: "100%",
                maxHeight: "80vh",
                margin: "0 auto",
                objectFit: "contain",
              }}
            />
          </div>
        )}
        <div
          style={{
            padding: "2rem",
            textAlign: "center",
          }}
        >
          <h2
            style={{
              fontSize: "1.5rem",
              fontWeight: "bold",
              color: "white",
            }}
          >
            {story.title}
          </h2>
          <p>{story.description}</p>
        </div>
      </div>
    </div>
  )
}

export default Modal
