import React from "react"
import TextContent from "./TextContent"
import { Box } from "theme-ui"
import cloudinaryString from "../helpers/cloudinaryString"
import MultipleLinksSelector from "./Links/MultipleLinksSelector"

export default function ContentSection({
  story,
  backgroundGradient = 0,
  backgroundGradientType = "dark",
  sx,
}) {
  if (!story) return null

  const {
    body,

    description,
    id,
    name,
    subtitle,
    tags,
    title,
    media,
    ctaLinks,
  } = story || {}

  const backgroundImage =
    cloudinaryString(media?.[0].cloudinaryId, 2000) ?? null

  const backgroundGradientStyle =
    backgroundGradientType === "light"
      ? `rgba(255,255,255,${backgroundGradient})`
      : `rgba(0,0,0,${backgroundGradient})`

  return (
    <Box
      name={name}
      sx={{
        backgroundImage: `url(${backgroundImage})`,
        minHeight: "100vh",
        backgroundPosition: "center",
        backgroundSize: "cover",
        position: "relative",
        backgroundAttachment: ["", "", "fixed"],
        "::before": {
          content: "''",
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%,-50%)",
          width: "100%",
          height: "100%",
          background: backgroundGradientStyle,
        },
        ...sx,
      }}
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%,-50%)",
          width: "100%",
          textAlign: "center",
          color: "white",
          justifyContent: "center",
        }}
      >
        <TextContent
          id={id}
          title={title}
          subtitle={subtitle}
          description={description}
          body={body}
        />

        <MultipleLinksSelector links={ctaLinks} />
      </Box>
    </Box>
  )
}
