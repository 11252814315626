import React, { useState, useEffect } from "react"
import styled from "styled-components"
import { Box } from "theme-ui"
import { graphql } from "gatsby"
import { ParallaxProvider } from "react-scroll-parallax"
import Layout from "../components/Layout"
import HeroFooter from "../components/UIPageSections/HeroFooter"
import Shout from "../components/shout"
import MenuShowcase from "../components/UIPageSections/MenuShowcase"
import ParallaxImage from "../components/parallaxImage"
import EventsShowcase from "../components/UIPageSections/EventShowcase.js/EventsShowcase"
import Curbside from "../components/UIPageSections/Curbside"
import Oktoberfest from "../components/UIPageSections/Oktoberfest"
import SeasonalButtons from "../components/SeasonalButton"
import seasons from "../data/Seasons"
import cloudinaryOptimize from "../helpers/cloudinaryOptimize"
import ContentSection from "../components/ContentSection"
import useStories from "../hooks/useStories"
import DocksideSubscribe from "../components/UIPageSections/DocksideSubscribe"
import EventsPopupModal from "../components/EventsPopupModal"
import Modal from "../components/Modal"
import GetSocialBar from "../components/GetSocialBar"

// ? =========================
// ? === Default Component ===
// ? =========================

export default function Index({ data }) {
  const siteData = data.siteMetaData
  const [modalOpen, setModalOpen] = useState(true)

  useEffect(() => {
    const script = document.createElement("script")
    script.src = "https://cdn.lightwidget.com/widgets/lightwidget.js"
    script.async = true
    document.body.appendChild(script)
    return () => {
      document.body.removeChild(script)
    }
  })

  const { getStoryByTag } = useStories()

  const hero = getStoryByTag("homepage-hero")
  const beerSection = getStoryByTag("homepage-beer")
  const boatSection = getStoryByTag("homepage-boat")
  const popupStory = getStoryByTag("popup")
  console.log("popup", popupStory)

  return (
    <Layout pageTitle="home" hasTopShoutBanner>
      {popupStory && modalOpen ? (
        <Modal closeModal={() => setModalOpen(false)} story={popupStory} />
      ) : (
        ""
      )}
      <ContentSection story={hero} backgroundGradient={0.15} />
      {/* <Oktoberfest /> */}
      <DocksideSubscribe />
      <Box sx={{ height: "100vh", iframe: { height: "100%" } }}>
        <iframe
          width="100%"
          height="100%"
          src="https://www.youtube.com/embed/OJAsQYR7azw?controls=0"
          title="Dockside Brewery"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
        ></iframe>
      </Box>

      <Box
        sx={{
          ".section-shout": {
            position: "relative",
            ".shout-logo": {
              borderRadius: "30px",
              marginBottom: "2rem",
              background: "$secondary",
            },
          },
        }}
        className="section section-shout is-hidden-desktop"
      >
        <Shout />
      </Box>
      {/* <IglooPark /> */}
      <Curbside />
      {/* <DraftOfTheWeek>
        <FeaturedBeer />
      </DraftOfTheWeek> */}

      <GetSocialBar />

      <Box
        sx={{
          position: "relative",
          padding: "1rem 1rem 2rem",
          backgroundColor: "#ffffff",
          backgroundImage:
            'url("https://www.transparenttextures.com/patterns/brick-wall.png")',
        }}
      >
        <MenuShowcase />
      </Box>

      {/* <SectionAllYear>
        <h4>Experience Dockside All Year</h4>
        <SeasonalButtons data={seasons} hardLink />
      </SectionAllYear> */}

      <ContentSection
        story={beerSection}
        backgroundGradient={0.4}
        sx={{
          minHeight: ["60vh"],
          ".title": {
            fontSize: ["1.5rem", "1.5rem", "1.75rem"],
          },
          ".primaryButton": {
            variant: "buttons.quaternary",
          },
        }}
      />

      <EventsShowcaseContainer style={{ padding: 0 }}>
        <EventsShowcase />
      </EventsShowcaseContainer>

      <div style={{ background: "white", height: "100%", display: "flex" }}>
        <iframe
          src="https://cdn.lightwidget.com/widgets/9c0b3b3b3bfb521cbeacd08e8d0314e3.html"
          scrolling="no"
          allowtransparency="true"
          className="lightwidget-widget"
          style={{
            width: "100%",
            overflow: "hidden",
            background: "white",
          }}
        ></iframe>
      </div>

      <ContentSection
        story={boatSection}
        backgroundGradient={0.2}
        sx={{
          minHeight: ["60vh"],

          ".primaryButton": {
            variant: "buttons.quaternary",
          },
        }}
      />
      <HeroFooter siteData={siteData} />
    </Layout>
  )
}

// ? ==============
// ? === Styles ===
// ? ==============

const SectionAllYear = styled.section`
  position: relative;
  padding: 2rem 1rem;
  background-color: ${({ theme }) => theme.primary};
  @media (min-width: 1100px) {
    padding: 2rem;
  }
  h4 {
    color: white;
    font-size: 1.75rem;
    text-align: center;
    text-transform: uppercase;
    @media (min-width: 1024px) {
      font-size: 2rem;
    }
    @media (min-width: 1424px) {
      font-size: 2.5rem;
    }
  }
`

const DraftOfTheWeek = styled.section`
  background: ${props => (props.theme.light ? props.theme.light : "black")};
  padding: 2rem 1rem;

  @media (min-width: 1024px) {
    padding: 4rem 2rem;
  }
`

const ParallaxContainer = styled.div``

const EventsShowcaseContainer = styled.div`
  padding: 2rem 1.25rem;
  background: linear-gradient(rgba(0, 86, 125, 0.9), rgba(0, 86, 125, 0.9)),
    url("https://res.cloudinary.com/gonation/image/upload/w_1200/q_auto/v1594310040/sites/dockside-brewing/events-module-bg.jpg");
  background-position: center;
  background-size: cover;
  color: white;

  @media (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
    padding: 2rem;
  }

  .subtitle {
    color: $light;
    margin-top: 1rem;
  }

  .title {
    font-size: 3rem;
  }
  .subtitle {
    font-size: 2rem;
    margin-bottom: 4rem;
  }
  .active-event {
    .title {
      font-size: 2.5rem;
    }
    .subtitle {
      font-size: 1.75rem;
    }
  }
  .event-tags {
    max-width: 400px;
  }
  .button {
    font-size: 1.5rem;
  }
`

export const query = graphql`
  {
    siteMetaData {
      avatar {
        imageBaseUrl
      }
      city
      desc
      cover {
        imageBaseUrl
      }
      hours {
        fri {
          close
          open
        }
        mon {
          close
          open
        }
        sat {
          close
          open
        }
        sun {
          close
          open
        }
        thu {
          close
          open
        }
        tue {
          close
          open
        }
        wed {
          close
          open
        }
      }
      lastPricelistUpdate {
        sec
        usec
      }
      links {
        facebook
        instagram
        twitter
        youtube
        website
      }
      loc
      slug
      name
      phone
      state
      street
      zip
      bizID
    }
  }
`
