import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import styled from "styled-components"
import Facebook from "./icons/facebook"
import Instagram from "./icons/Instagram"
import Twitter from "./icons/Twitter"
import Guy from "./icons/GoNationMan"

// Styled component for the banner
const SocialBanner = styled.div`
  display: flex;
  flex-direction: column; // Arrange title and icons vertically
  justify-content: center;
  align-items: center;
  background-color: ${({ theme }) =>
    theme.secondary}; // Customize the background color as needed
  padding: 20px 0; // Adjust padding for the desired height of the banner
`

// Styled component for the icon links
const IconLink = styled.a`
  margin: 0 1.5rem;
  &:first-child {
    margin-left: 0;
  }
  &:last-child {
    margin-right: 0;
  }
`

// Styled component for the title
const BannerTitle = styled.h2`
  color: #fff; // Customize text color
  margin-bottom: 10px; // Space between title and icons
  font-size: 24px; // Adjust font size as needed
`

const GetSocialBar = ({ iconWidth = "50px", iconFill = "#fff" }) => {
  const { siteMetaData } = useStaticQuery(graphql`
    query SocialBarQuery {
      siteMetaData {
        links {
          facebook
          instagram
          twitter
          youtube
        }
        slug
      }
    }
  `)

  const getSocialIcon = social => {
    switch (social) {
      case "facebook":
        return <Facebook fill={iconFill} width={iconWidth} />
      case "instagram":
        return <Instagram fill={iconFill} width={iconWidth} />
      case "twitter":
        return <Twitter fill={iconFill} width={iconWidth} />
      default:
        return null
    }
  }

  const renderSocialIcons = () => (
    <>
      {Object.keys(siteMetaData.links).map(
        (social, idx) =>
          siteMetaData.links[social] && (
            <IconLink
              key={social}
              target="_blank"
              rel="noopener noreferrer"
              href={siteMetaData.links[social]}
            >
              {getSocialIcon(social)}
            </IconLink>
          )
      )}
    </>
  )

  return (
    <SocialBanner>
      <BannerTitle className="title">Connect With Dockside</BannerTitle>
      <div style={{ display: "flex", justifyContent: "center" }}>
        {renderSocialIcons()}
      </div>
    </SocialBanner>
  )
}

export default GetSocialBar
