import React from "react"
import { Link as GatsbyLink } from "gatsby"
import styled from "styled-components"
import { Link } from "react-scroll"
import cloudinaryOptimize from "../helpers/cloudinaryOptimize"
import cloudinaryString from "../helpers/cloudinaryString"

const index = ({ data, hardLink }) => {
  const getSeasonImg = img => ({
    backgroundImage: `linear-gradient( rgba(0, 0, 0, 0.05), rgba(0, 0, 0, 0.3) ), url(${cloudinaryString(
      img,
      250
    )})`,
    backgroundPosition: "center",
    backgroundSize: "cover",
  })

  const renderSeasons = () =>
    data.map(({ name, img }) => (
      <Box>
        {hardLink ? (
          <GatsbyLink
            style={getSeasonImg(img)}
            className="season-box"
            to={`/dockside-all-year`}
          >
            <p>{name}</p>
          </GatsbyLink>
        ) : (
          <Link
            to={name}
            offset={-100}
            duration={750}
            smooth
            className="season-box"
            style={getSeasonImg(img)}
          >
            <p>{name}</p>
          </Link>
        )}
      </Box>
    ))
  return <div className="seasonal-button-columns">{renderSeasons()}</div>
}

export default index

const Box = styled.div`
  padding: 1rem;
  padding-top: 0;
  padding-bottom: 1rem;
  :first-of-type {
    padding-top: 1rem;
  }

  @media (min-width: 768px) {
    padding-right: 2rem;
    padding-top: 2rem;
    padding-bottom: 2rem;
    :first-of-type {
      padding-top: 2rem;
      padding-left: 2rem;
    }
  }

  flex: 1;
`
